<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Debit Credit</h4> 
                    <router-link v-if="backtothelist" @click.native="backToList()" :to='"#"'><< Back to the List</router-link> 
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                    
              </CCol> 
              <CCol md="2">  
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>  
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 
              <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
              </CRow>  
              <CRow>
                  <CCol col="12" class="text-center">                   
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                  </CCol> 
              </CRow>   

                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                    </b-card-group>

              <CRow v-if="daterange">    
                             
              <CCol md="3">                    
                    <CInput
                        label="From Date"
                        type="date"
                        vertical
                        v-model="fromDate" 
                        @change="startDateOnChange" 
                      />       
              </CCol>
              <CCol md="3">                    
                    <CInput
                        label="To Date"
                        type="date"
                        vertical
                        v-model="toDate"
                        @change="endDateOnChange"                       
                      />         
              </CCol>  
              <CCol md="6">  
                      <div role="group">
                        <label for="input-live">Select User</label>                  
                      <vue-typeahead-bootstrap placeholder="Phone Number" 
                        v-model="phoneNum" :data="optNumber">
                        <template #append>
                          <CButton color="primary" v-on:click="actionSearch()">Search</CButton>
                          <CButton color="warning" v-on:click="actionClear()">Clear</CButton>
                        </template>
                      </vue-typeahead-bootstrap>
                      </div>
              </CCol>                   
              </CRow>   

          <span v-if="header">   
          <CRow>
             <CCol col="2">  
                Select Wallet :
             </CCol>
             <CCol col="10">  
                <b-form-select @change="walletChange" v-model="moptWallet" :options="optWallet"></b-form-select>
             </CCol>
          </CRow><br/>
          <table class="table">            
            <thead>
              <tr>  
                <th scope="col">User ID</th>            
                <th scope="col">Mobile Number</th>
                <th scope="col">UserName</th>
                <th scope="col">Total Debit</th>
                <th scope="col">Total Credit</th>
              </tr>
            </thead>
            <tbody>
              <tr>  
                <td>{{ userId }}</td>            
                <td>{{ mobileNumber }}</td>
                <td>{{ userName }} </td> 
                <td>{{ ttlDebit }}</td>
                <td>{{ ttlCredit }}</td>
              </tr>
            </tbody>
          </table><br/> 
          </span>

        <div class="table-responsive">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="trasactionDate" sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

                <template #cell(index)="{ item }">
                  {{ items.indexOf(item) + 1 }}
                </template>

                <template v-slot:cell(trasactionDate)="{ item }"> 
                    <span v-if="(typeof item.trasactionDate)">
                    {{ item.trasactionDate }}
                    </span>
                </template>  

                <template v-slot:cell(amount)="{ item }"> 
                    {{ item.amount | formatNum }}
                </template> 

                 <template v-slot:cell(details)="{ item }">
                    <span v-if="item.totalCredits !== 0 || item.totalDebits !== 0">                 
                    <router-link @click.native="actionDetail(item.userIdentify, item.mobileNumber, item.userName, 
                                item.totalDebits, item.totalCredits, item.walletListElements )" :to='"#"'> Details </router-link>
                     </span>
                    <span v-else>No</span> 
                </template>


          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>      
          
      </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import AuthService from '@/api/AuthService.js';
import ReportService from '@/api/ReportService.js';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';

 export default {
    name: "debitcredit",
    components: { VueTypeaheadBootstrap },     
    data() { var date = new Date();
      return {
        optNumber:[],
        baseItems:[],
        baseUserId:[],
        phoneNum:'',
        srcData  :[],
        optWallet: [],
        moptWallet:'',
        userIdList:[],
        mUserId:'',
        optUserId:[],
        spinner:false,
        header:false,
        daterange:true,
        action : '',
        userId : '',
        mobileNumber : '',
        userName : '',
        ttlDebit : 0,
        ttlCredit: 0,
        backtothelist:false,
        main:[],
        list:[],
        columns : [],
        rows : [],
        data : [],
        items:[], 
        detail:[],             
        fields: [], 
        walletId:[],
        selectId:'',        
        currentPage: 1,
        perPage: 10,
        filter: null,        
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),       
        toDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59).toISOString().substr(0, 10), 
        formPasswd:false,
        filename:"debit-credit-report.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
    },
    created () {      
       //8262322274348714098162030928222446 
       this.setDefaultPasswd();   
       this.fetchUsers();
       this.setMainFields(); this.setColumns();              
    },
    methods: {

      actionClear(){
          this.phoneNum = '';
          this.items = [];
      },
     
      actionSearch(){
          this.spinner = true;  let params = {};

          if(this.phoneNum){            
            var temp = this.baseItems;
            var phoneNum = this.phoneNum;
            var user = this.baseUserId;       
            let index = temp.findIndex(temp => temp === phoneNum );
            this.mUserId = user[index]; 

            params['userId']   = this.mUserId;
            params['fromDate'] = this.fromDate.toString()+"T00:00:00";
            params['toDate']   = this.toDate.toString()+"T23:59:59";  
            //console.log(params); 

            this.getDebitCreditReport(params);

        } else {
           this.msg   = 'Please select phone number first !'; 
           this.color = 'warning'; this.seen  = true; this.spinner = false; 
           setTimeout( () =>  this.seen=false , 5000);
        }      
          
      },

      actionDetail(userId,mobileNumber,userName, ttlDebit, ttlCredit, detail){ 
          this.spinner=true;          
          this.userId =  userId; 
          this.mobileNumber =  mobileNumber; 
          this.userName =  userName;
          this.ttlDebit = ttlDebit;
          this.ttlCredit = ttlCredit;

          this.setDetailFields(); this.setColumns(); this.resetPagination(); 
          this.main = this.items;

          this.arrangeDetail(detail);        
          
          this.daterange = false; this.header = true; this.backtothelist = true;
      },

      walletChange(){
          this.selectId = this.moptWallet;
          var detail = this.srcData;
          this.arrangeDetail(detail);
      },

      arrangeDetail(detail){  //console.log(detail); 
          var wallet   = new Array();  var idlist = []; var wallletList =[];
          this.srcData = detail; var defaultId = '';

          var ttlCredit = 0; var ttlDebit = 0;

          for (var key in detail) {  
              var rows = detail[key];  
              for (var key2 in rows) { 
                 if(key2==="walletId"){  
                     var wid = rows[key2]; 
                     this.walletId.push(wid); idlist.push(wid);                   
                     wallet[wid]= new Array();                                
                 } 
                 if(key2==="walletName"){  
                    var wid = rows[key2]; 
                    wallletList.push(wid);
                 }
              }
          }         

          for (var key in detail) {  
                var rows = detail[key];                            
                for (var key2 in rows) {                   
                  if(key2==="creditList" || key2==="debitList"){  
                      var row = rows[key2]; 
                      for (var key3 in row){
                          var val = row[key3];
                          val['walletName'] = rows.walletName;  
                          //console.log(val);                                           
                          for (var key4 in val){
                              if(key4==="walletId"){
                                  var ids = val[key4]; 
                                  wallet[ids].push(val);
                                  defaultId = ids;
                              }                             
                          }
                      }
                  }            
                }
          }          

          var mvStsSrc=[];
          var arrMvSts = idlist;
          for (var keyy in arrMvSts) {  
            var rw = arrMvSts[keyy];
          //for (const item3 of arrMvSts){               
            mvStsSrc.push({ value: rw , text: wallletList[keyy] });
          }
          this.optWallet = mvStsSrc;         
          
          var keyid  = (!this.selectId) ? defaultId : this.selectId;
          this.moptWallet = keyid;
          var data   = wallet[keyid];

          for (var key in data) {  
              var rr = data[key];
              if(rr.type==="CREDIT") { ttlCredit += parseFloat(rr.amount); }
              if(rr.type==="DEBIT") { ttlDebit += parseFloat(rr.amount); }
          }

          this.ttlDebit = ttlDebit.toFixed(2);
          this.ttlCredit = ttlCredit.toFixed(2);  //console.log(data);

          this.items = data; 
          this.setDataRows(data,"trasactionDate","amount");
          this.spinner=false; 
      },

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },

      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },     

      generateWorkbook(){
         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {};  var colkey =[];       

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          }           
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3];                   
                  var ckey = colkey[key4]; 
                  if(key3===ckey){ init[key4] = val;}              
                }
              }
              data.push(init); 
          } 
       
         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
         
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click();  this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      getDebitCreditList(){
        let params = {};
        params['userId'] = "all";
        params['fromDate'] = this.fromDate+"T00:00:00";
        params['toDate'] =  this.toDate+"T23:59:59";

        this.getDebitCreditReport(params); 
      },

      startDateOnChange(){ 
        //   this.spinner=true;
        //   this.getDebitCreditList();
          
      },

      endDateOnChange(){ 
        //   this.spinner=true;
        //   this.getDebitCreditList();         
      },

      setDataRows(resp,keydate,keyamount){
            
            for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){ 
                      var strdate = resp2[key2]; 
                      var dt = new Date(strdate).toISOString().substr(0, 19).replace("T"," ");
                      //console.log(dt);
                      resp[key][keydate] = strdate.substr(0, 19).replace("T"," ");                     
                    }
                    if(key2 === keyamount){ 
                       resp[key][keyamount] = resp2[key2];
                    }
                 }
            }           

          this.data  = resp;               
      },

      setFrontDataRows(resp,keynum1,keynum2){

          for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keynum1){ 
                       resp[key][keynum1] = resp2[key2];
                    }
                    if(key2 === keynum2){ 
                       resp[key][keynum2] = resp2[key2];
                    }
                 }
            }

          this.data  = resp;
      },

      getFilterData(){
         var resp  = this.list;
         if(!this.header){
            this.setFrontDataRows(resp,"totalCredits","totalDebits");
         } else {
            this.setDataRows(resp,"trasactionDate","amount");
         }

      },    

      setColumns(){ 
        this.columns = [];
        var fields = this.fields; let row = {}; var cols = [];
        var except = ['index','details'];
        for (var key in fields) {  
           var vkey = fields[key].key;
           if(!except.includes(vkey)){         
             row['label'] = fields[key].label;
             row['field'] = fields[key].key;
             var temp = JSON.stringify(row); cols.push(temp);
           }
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      resetPagination(){
         this.perPage = 10; this.currentPage = 1;
      },

      formatNum(value){
         return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
      }, 

      getExcelDateTime(strdate){
         var result ={};
         var temp = strdate.toString().split(".");
         var temp = temp[0].split("T"); var time = temp[1];
         var temp = temp[0].split("-");
         var date = temp[1]+"/"+temp[2]+"/"+temp[0];         

         //var myJsonString = JSON.stringify(result);
         var res = this.replaceAll(myJsonString,'"*','');
         var res = this.replaceAll(res,'*"','');
         //var as  = JSON.parse(res); 
         //return as.date;
         console.log(as);
      },

      dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           value = this.replaceAll(value,"-","/");
           return value;            
      },  

      replaceAll(string, search, replace) {
        return string.split(search).join(replace);
      },      

      setMainFields(){
          let result =   [{ key: 'index',label : '#'},
                          { key: 'userIdentify',label : 'User Id',sortable: true },
                          { key: 'mobileNumber',label : 'Mobile Number', sortable: true }, 
                          { key: 'userName', label : 'User Name', sortable: true }, 
                          { key: 'totalDebits', label : 'Total Debits', sortable: true},
                          { key: 'totalCredits',label : 'Total Credits',sortable: true },
                          { key: 'details',label : 'Details'} 
                         ]; 

          this.fields = result;
      },

      setDetailFields(){
         let result       = [ { key: 'index',label : '#'}, 
                              {key:'transactionRef',label:'Transaction Ref',sortable: true},
                              {key: 'trasactionDate',label:'Trasaction Date',sortable: true},
                              {key: 'walletId',label:'Wallet ID',sortable: true},
                              {key: 'walletName',label:'Wallet Name',sortable: true},
                              {key:'type',label:'Type',sortable: true},
                              {key:'movementType',label:'Movement Type',sortable: true},   
                              {key:'amount',label:'Amount',sortable: true},                           
                              {key:'runningWalletBalance',label:'Wallet Balance',sortable: true} 
                            ] ; 

          this.fields = result;
      },

      backToList(){ 
          this.daterange = true; this.backtothelist = false; this.header = false; this.resetPagination();
          this.setMainFields();  this.setColumns();
          this.items = this.main; 
          var resp  = this.main;
          this.setFrontDataRows(resp,"totalCredits","totalDebits");
      },      

      getDetailDebit: function(params) {
        ReportService.getDetailDebit(params).then(resp => {  //console.log(resp);
            
            this.detail = resp; 
            // console.log(resp);
            // console.log("record debit : " + resp.length); 
            // //this.setDataRows(resp,"trasactionDate","amount");            

        }, error => {
           this.loading = false;
        });
      },

      getDetailCredit: function(params) {
        ReportService.getDetailCredit(params).then(resp => {  //console.log(resp); 
     
            var debit = [];   debit = this.detail;          
            // console.log(resp);
            // console.log("record credit : " + resp.length); 
            var finalObj = debit.concat(resp);
            this.items = finalObj;   this.detail=[];    

            this.setDataRows(finalObj,"trasactionDate","amount");
            // this.filter = '';  this.spinner = false;

        }, error => {
           this.loading = false;
        });
      },

      joinDetDebitCredit(params){ //console.log(params);
        
         this.getDetailDebit(params);
         this.getDetailCredit(params);

         this.filter = '';  this.spinner = false;
      },

      getDebitCreditReport: function(params) {
        ReportService.getDebitCreditReport(params).then(resp => {   console.log(resp);

            if(resp.status === 500){
                this.msg   = "Sorry, data not defined, please select other criteria !"; 
                this.color = 'info'; this.seen  = true;                
                setTimeout( () =>  this.seen=false , 5000);
            } else {
                this.items = resp;  //this.data  = resp; 
                this.setFrontDataRows(resp,"totalCredits","totalDebits");
            }
            this.spinner = false;
        }, error => {
           this.spinner = false;
        });
      },

      fetchUsers: function() {
            AuthService.getUserList({page:0, size:10000}).then(resp => {               

                var users = resp.payload;  
                var items = []; var userId=[];

                for (var key in users) {
                    var countryCode = users[key].mobileNumber.countryCode;
                    var phoneNum = users[key].mobileNumber.number;
                    var ccphoneNum = countryCode+phoneNum;   //console.log(ccphoneNum);
                    items.push(ccphoneNum);
                    //items.push(users[key].mobileNumber.number);
                    userId.push(users[key].userIdentify.userId);
                }

                //this.optUserId = userId;  
                this.baseItems = items;
                this.baseUserId = userId;
                this.optNumber = items;          

            }, error => {
            this.spinner = false;
            });
      },

    }
 }; 

</script>

<style>
   [role~=listbox]{ background: black; }
</style>
